// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery"

Rails.start()
ActiveStorage.start()

$(function(){
  $(".menu-button,.menu-except-area").on("click", function(){
    $(".menu-sp").toggleClass("menu-open");
    $(".menu-button").toggleClass("menu-open");
    $(".menu-except-area").toggleClass("menu-open");
  });
});

$(function(){
  $('.menu-sp a[href]').on('click', function(event) {
    $('.menu-except-area').trigger('click');
  });
});
  
//$(function(){
//  var height=window.innerHeight;
//  document.documentElement.style.setProperty( '--vh', height/100 + 'px');
//  $('#top-text').hide().fadeIn(0);
//});
